import axios from 'axios'
import { parseSnakeCase } from '../utils/helpers'
// const baseUrl = 'systems.km.develop.multikrd.com'
// const baseUrl = 'https://d6ec-189-197-81-219.ngrok.io/'
const baseUrl = 'https://develop.multikrd.com/'

const postSignup = async ({ user, code }) => {
  let response
  try {
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      data: {
        user: parseSnakeCase(user),
        sms_code: code
      }
    }
    response = await axios(baseUrl + 'api/btc/signup', request)
    return response
  } catch (error) {
    return { success: false, error }
  }
}

const sendTwoFactorCode = async ({ mobilePhone }) => {
  let response
  try {
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      data: {
        mobile_phone: mobilePhone
      }
    }
    response = await axios(baseUrl + 'api/btc/signup/sms', request)
    return response
  } catch (error) {
    return { success: false, error }
  }
}

export { postSignup, sendTwoFactorCode }
