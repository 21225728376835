import React from 'react'
import ImgForm from '../assets/images/contact.png'
import SignupForm from '../components/SignupForm'

export default function Signup () {
  return (
    <>
      <div className='px-md-3 px-lg-5 pt-5'>
        <div className='row p-5 pb-3'>
          <div className='web-header'>Interested in Multikrd?</div>
        </div>
        <div className='row pt-0 pt-5-md pb-5'>
          <div className='col-6 d-none d-md-inline'>
            <div className='large-text p-5'>Let's get started!</div>
            <img src={ImgForm} alt='imgForm' />
          </div>
          <div className='col-12 col-md-6 px-5 px-md-0'>
            <div className='large-text p-5 px-0'>Create an Account</div>
            <SignupForm emailRecipient='sales@multikrd.com' />
          </div>
        </div>
      </div>
    </>
  )
}
